import { Injectable } from '@angular/core';
import { StorageService } from 'app/shared/services/StorageService';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class IosAppService {
    private isRunningInIOSAppSubject = new BehaviorSubject<boolean>(localStorage.getItem('ios_app_version') !== null);
    isRunningInIOSApp$ = this.isRunningInIOSAppSubject.asObservable();

    constructor(private storageService: StorageService) {
        this.storageService.monitorKey('ios_app_version', localStorage).subscribe(event => {
            this.isRunningInIOSAppSubject.next(event.newValue !== null);
        });
    }
}
